import { isStaff, ROLE_MPS_ADMIN } from "../security/roles";
import StorageService from "../services/storageService";
import { API_URL_KEY, http } from "../utils/http";
import Env from "./env";
import { parseURLQueryParams } from "../utils/utils";

export const REACT_APP_ENV = process.env.REACT_APP_ENV || "development";
export const API_CLIENT_ID = process.env.REACT_APP_API_CLIENT_ID;
export const API_CLIENT_SECRET = process.env.REACT_APP_API_CLIENT_SECRET;

// export const API_DOMAIN = process.env.REACT_APP_MYP_API_URL
//   ? process.env.REACT_APP_MYP_API_URL
//   : "testservice.mypatientspace.com";
// export const DEFAULT_API_URL = "https://testservice.mypatientspace.com";
// export const API_URL = "https://" + API_DOMAIN;
// export const API_URL = "http://localhost:8080";

export const API_ENDPOINTS = {
  getComorbidityTemplates: (patientTypeId) => `/api/artifact/comorbidityTemplates?patientTypeId=${patientTypeId}&page=0&size=100`,
  postComorbidityTemplate: () => `/api/artifact/comorbidityTemplates`,
  deleteComorbidityTemplate: (comorbidityTemplateId) => `/api/artifact/comorbidityTemplates/${comorbidityTemplateId}`,
  locateUser: `/api/signup/locateUser`,
  get_token: "/oauth/token",
  get_user_info: "/api/users/userDetails",
  acceptInvite: () => `/api/signup/acceptInvite`,
  fetchSpaceDetailsFromInviteCode: () =>
    `/api/signup/fetchSpaceDetailsFromInviteCode/`,
  acceptTerms: () => `/api/signup/acceptTermsAndConditions`,
  confirmationCode: () => `/api/signup/activateWith2FACode`,
  confirmationCodeWithTerms: () => `/api/signup/activateWithTerms`,
  setCredentials: () => `/api/signup/setLoginCredentials`,
  getSpaceInfo: () => "/api/space",
  getFacilityInfo: () => "/api/admin/facility",
  getAllFacilities: () => "/api/mpsadmin/facilities",
  loadOrganization: (id) => `/api/mpsadmin/facilities/${id}`,
  updateFacility: (role, id) =>
    role === ROLE_MPS_ADMIN
      ? `/api/mpsadmin/facilities/${id}`
      : `/api/admin/facility`,
  addFacility: () => "/api/mpsadmin/facilities/",
  loadAdmins: (id) => `/api/mpsadmin/facilities/${id}/admin`,
  cloneFacility: (id) => `/api/mpsadmin/facilities/${id}/clone`,
  inviteAdmin: (orgId, withoutInvite) =>
    withoutInvite
      ? `/api/mpsadmin/facilities/${orgId}/admin/unauth`
      : `/api/mpsadmin/facilities/${orgId}/admin`,
  deleteAdmin: (orgId, id) => `/api/mpsadmin/facilities/${orgId}/admin/${id}`,
  loadStaff: () => `/api/org/staff/`,
  loadAllStaffForStaff: () => `/api/staff/staffs`,
  loadOneStaff: (staffId) => `/api/org/v1/staff/${staffId}`,
  inviteStaff: (withoutInvite) =>
    withoutInvite ? `/api/org/staff/withoutinvite` : `/api/org/staff`,
  deleteStaff: (staffId) => `/api/org/staff/${staffId}`,
  editStaff: (staffId) => `/api/org/staff/${staffId}`,
  loadPatients: () => "/api/staff/v1/getpatients",
  loadPatientsCount: () => "/api/staff/v1/getpatients/count",
  loadPatient: (patientId) =>
    `/api/staff/patients/${
      patientId // loadPatients: () => "/api/staff/patients/",
    }/details`,
  inviteAdminUser: (patientId, withoutInvite) =>
    withoutInvite
      ? `/api/staff/patients/${patientId}/adminmember/withoutinvite`
      : `/api/staff/patients/${patientId}/adminmember/`,
  inviteSpaceMember: (patientId, withoutInvite) =>
    withoutInvite
      ? `/api/staff/patients/${patientId}/member/withoutinvite`
      : `/api/staff/patients/${patientId}/member/`,
  getSpaceDetails: () => `/api/fetchSpaceDetailsForInviteCode`,
  addPatient: (withoutInvite) =>
    withoutInvite ? "/api/staff/patients/withoutinvite" : "/api/staff/patients",
  addPatientWithoutInvite: () => "/api/staff/patients/withoutinvite",
  deletePatient: (patientId) => `/api/staff/patients/${patientId}`,
  loadPatientTypesTemplate: () => `/api/artifact/mpstemplate`,
  loadPatientTypes: () => `/api/artifact/patienttypes`,
  loadSinglePatientType: (id) => `/api/artifact/patienttypes/${id}`,
  topicsFromMasterData: (id, type) =>
    `/api/artifact/masterdata/topics?${
      id ? `pTypeid=${id}` : ""
    }&topicType=${type}`,
  addPatientType: () => `/api/artifact/patienttypes`,
  editPatientType: (id) => `/api/artifact/patienttypes/${id}`,
  addProfileAttribute: (profileId) =>
    `/api/artifact/patienttypes/${profileId}/attributes`,
  updateProfileAttribute: (profileId, attributeId) =>
    `/api/artifact/patienttypes/${profileId}/attributes/${attributeId}`,
  deleteProfileAttribute: (profileId, attributeId) =>
    `/api/artifact/patienttypes/${profileId}/attributes/${attributeId}`,
  addProfileMarkup: (profileId) =>
    `/api/artifact/patienttypes/${profileId}/screens`,
  loadMembers: (patientId) => `/api/staff/patients/${patientId}/members`,
  update_patient: "/api/patients/",
  addStatus: (role, patientId) =>
    isStaff(role)
      ? `/api/staff/patients/${patientId}/status`
      : `/api/patient/status`,
  addStatusFromSurvey: (patientId, surveyId) =>
    `/api/staff/patients/${patientId}/assignSurvey/${surveyId}`,
  addLibraryStatus: (patientId, libraryId) =>
    `/api/staff/patients/${patientId}/assignLibrary/${libraryId}`,
  addConsentDocumentStatus: (patientId, consentId) =>
    `/api/staff/patients/${patientId}/assignConsent/${consentId}`,
  loadStatuses: (role, patientId) =>
    isStaff(role)
      ? `/api/staff/patients/${patientId}/artifacts?artifactType=STATUSES_NEW`
      : `/api/patient/artifacts?artifactType=STATUSES_NEW`,
  loadStaffNotes: (patientId) =>
    `/api/staff/patients/${patientId}/artifacts?artifactType=STAFF_NOTES`,
  addStatusTemplate: () => `/api/artifact/messagetemplates`,
  loadStatusTemplates: () => `/api/artifact/messagetemplates`,
  loadTemplateParameters: (type) =>
    `/api/artifact/messageparameters?messageType=${type}&page=0&size=100`,
  loadSurveys: () => "/api/artifact/surveys/",
  loadSurvey: (surveyId) => "/api/artifact/surveys/" + surveyId,
  addSurvey: () => "/api/artifact/surveys/",
  fileUpload: () => "/api/uploadFiles",
  loadContentLibrary: () => "/api/artifact/libraries",
  loadContentLibraryByName: (name) => `/api/libraries?name=${name}`,
  addLibraryContent: () => "/api/artifact/libraries",
  loadTopics: () => "/api/artifact/librarytopics",
  loadArticle: (articleId) => "/api/artifact/libraries/" + articleId,
  loadArticlePatient: (articleId) => "/api/libraries/" + articleId,
  addLibraryTopic: () => "/api/artifact/librarytopics",
  loadTopic: (topicId) => "/api/artifact/librarytopics/" + topicId,
  addInfoPanel: (patientTypeId, isImport) =>
    `/api/artifact/patienttypes/${patientTypeId}/infopanel${
      isImport ? "?importContent=true" : ""
    }`,
  predefinedItems: (patientTypeId) =>
    "/api/artifact/patienttypes/" + patientTypeId + "/predefineditems",
  contentUrl: (fileKey) => "/api/content?fileKey=" + fileKey,
  loadPatientSurveys: (patientId) =>
    "/api/staff/patients/" +
    patientId +
    "/artifacts?artifactType=SURVEY_RESPONSES&page=0&size=100",
  loadAllPackages: () => "/api/mpsadmin/packages",
  loadProvisionedPackages: () => "/api/artifact/provisionedPackages/",
  carePlan: () => "/api/artifact/careplans/",
  carePlanById: (id) => "/api/artifact/careplans/" + id,
  carePlansByPatientType: (patientTypeId) =>
    "/api/artifact/careplans/patienttypes/" + patientTypeId,
  carePlanTemplates: (patientTypeId) =>
    `/api/artifact/patienttypes/${patientTypeId}/careplantemplates`,
  carePlansByPatient: (patientId) =>
    "/api/staff/patients/" + patientId + "/careplanView",
  carePlansByPatientForStaff: (patientId) =>
    "/api/staff/patients/" + patientId + "/careplanView?action-for=STAFF",
  patientCPForEdit: (patientId) =>
    "/api/staff/patients/" + patientId + "/careplan",
  assignCarePlanToPatient: (patientId) =>
    "/api/staff/patients/" + patientId + "/assignCarePlan",
  changePatientCarePlan: (patientId, carePlanId) =>
    `/api/staff/patients/${patientId}/assignCarePlan/${carePlanId}`,
  expressions: (patientTypeId) =>
    `/api/artifact/careplans/expressions/patient-type/${
      patientTypeId // expressions: () => "/api/artifact/careplans/expressions",
    }`,
  expressionsForMps: () => "/api/mpsadmin/careplans/expressions",
  consentDocuments: () => "/api/artifact/consentDocuments",
  consultants: "/api/consultants",
  messageTemplate: (templateId) =>
    "/api/artifact/messagetemplates/" + templateId,
  getPatientProfile: (patientId) =>
    "/api/staff/patients/" + patientId + "/profile",
  loadPatientDocuments: (patientId) =>
    `/api/staff/patients/${patientId}/artifacts?artifactType=PATIENT_DOCUMENTS`,
  loadPatientDevices: (patientId) =>
    `/api/staff/patients/${patientId}/artifacts?artifactType=DEVICES`,
  updatePatientProfileAttribute: (patientId) =>
    `/api/staff/patients/${patientId}/profile/attributes`,
  loadMyPatients: "/api/staff/mypatients",
  uiAttributeDataFields: "/api/artifact/attributeDataFields?size=500",
  uiAttributeDataFieldsMPS: "/api/mpsadmin/attributeDataFields",
  forgotPassword: "/api/signup/forgotPasswordResetRequest",
  resetPassword: "/api/signup/forgotPasswordSetNewPassword",
  uploadPatientProfilePhoto: (patientId) =>
    `/api/staff/patients/${patientId}/photo`,
  addMember: (withoutInvite, patientId) =>
    withoutInvite
      ? "/api/staff/patients/" + patientId + "/member/withoutinvite"
      : "/api/staff/patients/" + patientId + "/member/",
  addAdminMember: (withoutInvite, patientId) =>
    withoutInvite
      ? "/api/staff/patients/" + patientId + "/adminmember/withoutinvite"
      : "/api/staff/patients/" + patientId + "/adminmember/",
  loadStaffProfile: "/api/staff/",
  loadFacilityAdminProfile: "/api/admin/",
  loadMpsAdminProfile: "/api/mpsadmin/",
  removeMember: (patientId, memberId) =>
    "/api/staff/patients/" + patientId + "/members/delete?id=" + memberId,
  editMember: (patientId, memberId) =>
    `/api/staff/patients/${patientId}/member/${memberId}/details`,
  createMPSPackage: (facilityId, patientTypeId) =>
    `/api/mpsadmin/facilities/${
      facilityId // createMPSPackage: `/api/mpsadmin/mpspackages/`,
    }/patientTypes/${patientTypeId}/mpspackages`,
  loadMPSPackage: (packageId) => `/api/mpsadmin/mpspackages/${packageId}`,
  proceduresList: (patientTypeId) =>
    `/api/artifact/patienttypes/${patientTypeId}/attributes/name/MPS_PATIENT_PROCEDURE`,
  assessmentStats: "/api/artifact/v1/getassessments/",
  patientTypesForPackageForImport: (packageId) =>
    `/api/mpsadmin/patientTypesForPackageId?packageId=${
      packageId // assessmentStats: "/api/artifact/getassessments",
    }`,
  patientTypesForPackageForExport: (packageId) =>
    `/api/mpsadmin/allPatientTypesForPackageId?packageId=${packageId}`,
  importFromFacility: (packageId) =>
    `/api/mpsadmin/mpspackages/${
      packageId //   `/api/mpsadmin/mpspackages/${packageId}/updatefromFacilities/${facilityId}/patientTypes/${patientTypeId}`, // importFromFacility: (packageId, facilityId, patientTypeId) =>
    }/updateFromFacilities`,
  exportToFacility: (packageId, facilityId, patientTypeId) =>
    `/api/mpsadmin/facilities/${facilityId}/patientTypes/${patientTypeId}/updateFromPackage/${packageId}`,
  careTeams: `/api/org/careteams`,
  reInvitePatient: (patientId) => `/api/staff/patients/${patientId}/reinvite`,
  reInviteStaff: (staffId) => `/api/org/staff/${staffId}/reinvite`,
  mpsPackageDuplicate: (id, name) =>
    `/api/mpsadmin/mpspackages/${id}/cloneWithName/${name}`,
  mpsPackages: `/api/mpsadmin/mpspackages`,
  carePlanAsCSV: (patientId) => `/api/staff/patients/${patientId}/careplan-csv`,
  fireCompleteCarePlan: (patientId) =>
    `/api/staff/patients/${patientId}/fire-complete-careplan`,
  tagPatient: (patientId) => `/api/staff/mypatients/${patientId}`,
  restorePatient: (patientId) => `/api/staff/patients/${patientId}/reenable`,
  allSpaces: `/api/spaces?allStatus=true`,
  changeSpace: (newSpaceId) => `api/spaces/${newSpaceId}/currentspace`,
  editFacilityAdmin: (facilityId, staffId) =>
    `/api/mpsadmin/facilities/${facilityId}/admin/${staffId}`,
  taskTemplates: (patientTypeId) =>
    `/api/artifact/patienttypes/${patientTypeId}/tasktemplates`,
  taskTemplatesWoPatientTypeId: () => `/api/artifact/tasktemplates`,
  taskTemplate: (patientTypeId, templateId) =>
    `/api/artifact/patienttypes/${patientTypeId}/tasktemplates/${templateId}`,
  tasks: (patientId) => `/api/staff/patients/${patientId}/tasks`,
  clinicTasks: (scheduleId) =>
    `/api/staff/clinicTasks?scheduleId=${scheduleId}`,
  myTasks: `/api/staff/inbox/mytasks`,
  allTasks: `/api/staff/inbox/tasks`,
  outbox: `/api/staff/outbox/mytasks`,
  changePassword: `/api/updatePasswordOfLoggedInUser`,
  validateCarePlans: `/api/artifact/careplans/validate`,
  taskAssigneesForStaff: `/api/staff/staffandcareteams`,
  taskAssigneesForAdmin: `/api/org/staffandcareteams`,
  acceptTask: (taskId) => `/api/staff/tasks/${taskId}/accept`,
  completeTask: (taskId) => `/api/staff/tasks/${taskId}/complete`,
  reassignTask: (taskId) => `/api/staff/tasks/${taskId}/reassign`,
  task: (taskId) => `/api/staff/tasks/${taskId}`,
  executeTaskTemplate: (templateId, patientId) =>
    `/api/staff/tasktemplates/${templateId}/patients/${patientId}`,
  executeTaskTemplateForSchedule: (templateId, scheduleId) =>
    `/api/staff/tasktemplates/${templateId}/schedules/${scheduleId}`,
  facilityPatientStats: (facilityId) =>
    `/api/mpsadmin/facilities/${facilityId}/stats`,
  facilityAssignedPackages: (facilityId) =>
    `/api/mpsadmin/facilities/${facilityId}/mpspackages`,
  patientsWithBasicDetails: `/api/staff/patients/light`,
  addPackage: (facilityId, packageId) =>
    `/api/mpsadmin/facilities/${facilityId}/addNewPackage/${packageId}`,
  patientMembers: "/api/patient/members",
  patientDocuments: "/api/patient/artifacts?artifactType=PATIENT_DOCUMENTS",
  addDocument: (patientId) =>
    `/api/staff/patients/${
      patientId //patients app
    }/assignDocument/?sendAsStatusMessage=false`,
  patientDevices: "/api/patient/artifacts?artifactType=DEVICES",
  uiAttributesForFields: "/api/artifact/allUIFields",
  fieldsConfig: "/api/artifact/attributeUIFields",
  masterData: "/api/artifact/masterdata",
  masterDataAllRoles: "/api/masterdata",
  masterDataEdit: (id) => `/api/artifact/masterdata/${id}`,
  reopenTask: (id) => `/api/staff/tasks/${id}/reopen`,
  availableFeatures: `/api/mpsadmin/getAllAvailableFeatures`,
  facilitiesAndPatientTypes: `/api/mpsadmin/packageHelperPatientTypes?withoutPackages=true`,
  changeLogs: (patientId) =>
    `/api/staff/patients/${patientId}/logs?auditType=CHANGE_LOG`,
  accessLogs: (patientId) =>
    `api/staff/patients/${patientId}/logs?auditType=ACCESS_LOG`,
  // patientStats: `/api/artifact/v1/getPatientData`,
  patientStats: `/api/artifact/v2/getPatientData`,
  surveyResponse: (patientId, messageId) =>
    `/api/staff/patients/${patientId}/messages/${messageId}/surveyresponses`,
  directSurveyResponse: (patientId) =>
    `/api/staff/patients/${patientId}/surveyresponses`,
  taskUserParameters: `/api/artifact/taskUserParameters`,
  campaigns: "/api/artifact/campaigns",
  deleteFacilityOTP: (facilityId) =>
    `/api/mpsadmin/facilities/${facilityId}/getotp`,
  deleteFacility: (facilityId, OTP) =>
    `/api/mpsadmin/facilities/${facilityId}/withotp/${OTP}`,
  allResmedLocations: `/api/admin/mps/meta?type=LOCATIONS`,
  resmedUsers: (locId) =>
    `/api/admin/mps/meta?type=CLINICAL_USERS&locId=${locId}`,
  detailedAssessmentReport: (patientId) =>
    `/api/staff/patients/${patientId}/detailedReport`,
  therapyComplianceReport: (patientId) =>
    `/api/staff/patients/${patientId}/therapyComplianceReport`,
  mpsAdminPatientStats: (facilityId) =>
    `api/mpsadmin/facilties/${facilityId}/v2/getPatientData`,
  restoreStaff: (staffId) => `/api/admin/staff/${staffId}/undelete`,
  resetStaffPassword: (staffId) =>
    `/api/org/staff/${staffId}/setLoginCredentials`,
  carePlanAsHTML: (carePlanId) =>
    `/api/artifact/careplans/export/${carePlanId}/compressed`,
  exportPatientTypePackage: (patientTypeId) =>
    `/api/artifact/patientTypes/${patientTypeId}/mpspackages/export`,
  importPatientTypePackage: "/api/artifact/mpspackages/import?",
  allTaskTemplates: "/api/artifact/tasktemplates",
  staffTaskReport: "/api/staff/report/tasks",
  staffTaskReportTasks: "/api/staff/report/getTasks",
  taskAssigneesForTaskReport: `/api/staff/report/staffandcareteams`,
  previewInviteReminder: `/api/admin/remindForInvite`,
  previewInactivityReminder: `/api/admin/remindForInActivity`,
  previewSurveyCompletionReminder: `/api/admin/remindForSurveyIncompletion`,
  dbStats: (facilityId) => `/api/mpsadmin/facilities/${facilityId}/dbStats`,
  mpsAdminTemplateParameters: `/api/mpsadmin/messageparameters`,
  challengeFields: (code) => `/api/signup/fetchChallengeFields/${code}`,
  challengeFieldsFromFacilityCode: (code) =>
    `/api/signup/fetchChallengeFieldsFromFacilityCode/${code}`,
  fetchAddPatientProfileFromCode: (code) =>
    `/api/signup/fetchAddPatientProfileFromCode/${code}`,
  reminderAudits: `/api/admin/getReminderAuditLog`,
  acceptInviteV1: `/api/signup/v1/acceptInvite`,
  setLoginCredentialsV1: `/api/signup/v1/setLoginCredentials`,
  resetPatientCredentials: (patientId) =>
    `/api/staff/patients/${patientId}/setLoginCredentials`,
  activatePatient: (patientId) =>
    `/api/staff/patients/${patientId}/activatePatient`,
  staffStats: `/api/artifact/getStaffData`,
  masterDataCSVUpload: `/api/artifact/masterdataFromCSV`,
  mpsAdminStaffStats: (facilityId) =>
    `/api/mpsadmin/facilties/${facilityId}/getStaffData`,
  mpsAdminArtifactsStats: (facilityId) =>
    `/api/mpsadmin/facilties/${facilityId}/getArtifactsUsages`,
  artifactsStats: `/api/artifact/getArtifactsUsages`,
  patientSignUp: `/api/signup/patients`,
  emailConfirmation: (code) => `/api/signup/confirm/${code}`,
  signUpValidation: `/api/signup/validate`,
  signupProfileFromCode: (code) => `/api/signup/fetchProfileFromCode/${code}`,
  statusById: (patientId, statusId) =>
    `/api/staff/patients/${patientId}/status/${statusId}`,
  documentById: (patientId, docId) =>
    `/api/staff/patients/${patientId}/patientDocuments/${docId}`,
  loadLibForSignUp: (libId) => `api/signup/libraries/${libId}`,
  MPSAdminPatients: () => `/api/mpsadmin/patients`,
  markStatusAsRead: `/api/patient/setMessagesAsRead`,
  loadPersonalNotes: (patientId) =>
    `/api/staff/patients/${patientId}/artifacts?artifactType=PERSONAL_NOTES`,
  loadPatientInteractions: (patientId) =>
    `/api/staff/patients/${patientId}/artifacts?artifactType=INTERACTIONS`,
  patientCustomReport: (patientId) =>
    `/api/staff/patients/${patientId}/getCustomReport`,
  getPatientComorbidities: (patientId) =>
    `/api/staff/patients/${patientId}/comorbidityProfile`,
  addPatientComorbidity: (patientId) =>
    `/api/staff/patients/${patientId}/comorbidities`,
  deletePatientComorbidity: (patientId, comorbidityId) =>
    `/api/staff/patients/${patientId}/comorbidities/${comorbidityId}`,
  patientCustomReportTab: (patientId, tabId) =>
    `/api/staff/patients/${patientId}/getCustomReport?tabId=${tabId}`,
  patientCustomReportAnonymous: (patientTypeId) =>
    `/api/admin/getCustomReport?pTypeId=${patientTypeId}`,
  appointmentSchedule: `/api/artifact/schedules`,
  appointments: `/api/artifact/appointments`,
  appointmentStartNotification: (appointmentId) =>
    `api/artifact/appointments/${appointmentId}/sendLinkNotification`,
  slots: `/api/artifact/slots`,
  scheduleSlots: (scheduleId) => `/api/artifact/schedules/${scheduleId}/slots`,
  appointmentTemplates: `/api/artifact/appttemplates`,
  unsubscribe: `/api/signup/unsubscribe`,
  provisionedSurveys: (patientId) =>
    `/api/staff/patients/${patientId}/artifacts?artifactType=PROVISIONED_SURVEYES`,
  preferredLocale: (locale) => `/api/locales/${locale}`,
  staffSpecialNoteForPatient: (patientId) =>
    `/api/staff/patients/${patientId}/setSpecialNote`,
  videoRoom: (patientId, type) =>
    `/api/staff/patients/${patientId}/videoRooms?type=${type}`,
  joinRoom: (roomName) => `/api/signup/joinRooms/${roomName}`,
  joinRoomAuth: (roomName) => `/api/joinVideoRooms/${roomName}`,
  patientContacts: (patientId) =>
    `/api/staff/patients/${patientId}/contactpersons`,
  appointmentsFromTemplate: `/api/artifact/appointmentsFromTemplate`,
  videoRoomDetails: (roomName) => `/api/videoRooms/${roomName}/status`,
  patientsImportCSV: (patientTypeId) =>
    `/api/staff/patients/fromcsv?patientTypeId=${patientTypeId}`,
  unjoinRoomAuth: (roomName) => `/api/unjoinVideoRooms/${roomName}`,
  addPatientAsChannel: (channelName) =>
    `/api/staff/patients/aschannel?channelName=${channelName}`,
  careTeamsForAll: () => `/api/careteams`,
  importStaff: () => `/api/org/staff/fromcsv`,
  usageCounters: () => `/api/artifact/usageCounters`,
  carePlansByPatientTypeAndPatientId: (patientTypeId, patientId) =>
    `/api/artifact/careplans/patienttypes/${patientTypeId}?patientId=${patientId}`,
  patientSubmittedSurveys: (patientId) =>
    `/api/staff/patients/${patientId}/assessments`,
  patientSurveysResponsesById: (patientId, surveyId, page, size) =>
    `/api/staff/patients/${patientId}/surveys/${surveyId}?page=${page}&size=${size}`,
  savedPatientQueries: `/api/artifact/savedSearches`,
  publishedSchedules: `/api/artifact/publishedSchedules`,
  rules: (patientTypeId) =>
    `/api/artifact/patientTypes/${patientTypeId}/ruleMaps`,
  sendMessageToPatients: () => `/api/artifact/sendMessageToPatients`,
  runRuleMap: (patientTypeId, ruleMapId) =>
    `/api/artifact/patientTypes/${patientTypeId}/processRules/${ruleMapId}`,
  interactions_report: (patientId) =>
    `api/staff/patients/${patientId}/artifacts?artifactType=INTERACTIONS_REPORT&page=0&size=100`,
  anonAppointment: (appointmentId) =>
    `/api/signup/appointments/${appointmentId}`,
  appointmentAudits: (appointmentId) =>
    `/api/artifact/appointments/${appointmentId}?showLogs=true`,
  addPatientScreen: (patientTypeId) =>
    `/api/artifact/patienttypes/${patientTypeId}/screens/addpatient`,
  addPatientV2: `/api/staff/v2/patients`,
  patientInfoPanel: (patientTypeId, exportContent) =>
    `/api/artifact/patienttypes/${patientTypeId}/patientinfopanel?${
      exportContent ? "exportContent=true" : ""
    }`,
  patientInfoPanelGenerated: (patientTypeId) =>
    `api/artifact/patienttypes/${patientTypeId}/generateInfoPanelForPatientDefnOp`,
  staffInfoPanelView: `/api/artifact/generateInfoPanelForStaff`,
  staffInfoPanel: (isExport, isImport) =>
    `api/artifact/staffinfopanel${isExport ? "?exportContent=true" : ""}${
      isImport ? "?importContent=true" : ""
    }`,
  patientAppointments: (patientId, pageNumber, pageSize) =>
    `/api/artifact/appointments?patientId=${patientId}&page=${pageNumber}&size=${pageSize}`,
  appointmentAttributes: `/api/artifact/appointmentAttributes`,
  clinics: (clinicId) => `/api/staff/clinics${clinicId ? "/" + clinicId : ""}`,
  appointmentsImportCSV: () => `/api/staff/appointments/fromcsv`,
  patientAsCSV: (patientTypeId) =>
    `/api/admin/patientTypes/${patientTypeId}/allPatientsReport?`,
  clearCarePlanHistory: (patientId) =>
    `/api/staff/patients/${patientId}/clear-careplan-history`,
  recordPhoneCall: (patientId) =>
    `api/staff/patients/${patientId}/recordPhoneCall`,
  revertAppointmentCancel: (appointmentId) =>
    `/api/artifact/appointments/${appointmentId}/undelete`,
  ruleLogs: (patientTypeId, ruleId) =>
    `/api/artifact/patienttypes/${patientTypeId}/ruleLogs?ruleId=${ruleId}`,
  csvSchedules: () => `/api/artifact/schedules/fromcsv`,
  onboardingPatientDataTemplate: (patientId, type) =>
    `/api/signup/signupData?pId=${patientId}&type=${type}`,
  airViewNotes: (patientId) => `/api/staff/patients/${patientId}/airViewNotes`,
  clinicReports: () => `/api/staff/clinics/reports`,
  clinicReportsCSV: () => `/api/staff/clinics/reports-csv`,
  channel: (channelId) => `/api/staff/channels/${channelId}`,
  dialysisCenters: (patientId) =>
    `/api/staff/patients/${patientId}/dialysis-centers`,
  inviteOnboardingEvents: () => `/api/signup/events/invite`,
  // patientData: patientId => `/api/staff/patients/${patientId}/export-data`,
  patientData: (patientId) => `/api/staff/patients/${patientId}/dumpAll`,
  editPatientDocument: (patientId, docId) =>
    `/api/staff/patients/${patientId}/patientDocuments/${docId}`,
  clinicUploadReport: `/api/staff/csvLoadResults`,
  logout: () => `/api/logout`,
  reassignTasks: () => `/api/staff/tasks/reassign`,
  surveyResponses: (patientId, responseId) =>
    `/api/staff/patients/${patientId}/surveyresponses/${responseId}`,
  medications: (patientId) => `/api/staff/patients/${patientId}/medications`,
  importMedications: (patientTypeId) =>
    `/api/staff/patienttypes/${patientTypeId}/medications/fromcsv`,
  mfaStatus: () => `/api/setTwoFAStatus`,
  organizations: (orgId) => `/api/org/organizations${orgId ? "/" + orgId : ""}`,
  organizationStaff: (orgId, staffId) =>
    `/api/org/organizations/${orgId}/staff${staffId ? "/" + staffId : ""}`,
  roleTemplates: (roleId) =>
    `/api/org/roleTemplates${roleId ? "/" + roleId : ""}`,
  libraryExport: (libraryId) =>
    `/api/artifact/libraries/${libraryId}?exportContent=true`,
  surveyExport: (surveyId) =>
    `/api/artifact/surveys/${surveyId}?exportContent=true`,
  cloneLibrary: (libId) => `/api/artifact/libraries/${libId}/clone`,
  facilityDocuments: (docId) =>
    `/api/artifact/documents${docId ? "/" + docId : ""}`,
  carePlanClone: (id) => `/api/artifact/careplans/${id}/clone`,
  apptTemplateClone: (id) => `/api/artifact/appttemplates/${id}/clone`,
  consentClone: (id) => `/api/artifact/consentDocuments/${id}/clone`,
  ruleClone: (patientTypeId, ruleId) =>
    `/api/artifact/patientTypes/${patientTypeId}/ruleMaps/${ruleId}/clone`,
  externalIntegrations: () => `/api/admin/externalIntegrations`,
  supportStaff: (staffId) =>
    `/api/mpsadmin/support${staffId ? "/" + staffId : ""}`,
  supportPatients: () => `/api/support/patients`,
  getAllFacilitiesForSupport: () => "/api/support/facilities",
  resetPatientCredentialsBySupport: (patientId) =>
    `/api/support/patients/${patientId}/setLoginCredentials`,
  loadSupportStaffProfile: () => "/api/support/",
  commonMasterData: "/api/mpsadmin/masterdata",
  hcpReport: () => `/api/admin/hcp-monthly-report`,
  identifySelfSignupPatient: () => `/api/signup/identifySelfSignupPatient`,
  setLoginCredentialsV2: `/api/signup/v2/setLoginCredentials`,
  embeddedReports: (workspaceId, reportId) =>
    `/api/artifact/reports/embedded?workspaceId=${workspaceId}&reportId=${reportId}`,
  downloadEmbeddedReports: (workspaceId, reportId) =>
    `/api/artifact/reports/embedded/download?workspaceId=${workspaceId}&reportId=${reportId}`,
  reportIntegrations: () => `/api/artifact/reports/integrations`,
  subOrganizations: (orgId) => `/api/org/organizations?parentOrgId=${orgId}`,
  userEvents: () => `/api/user-events`,
  chargeItems: (patientId) => `/api/staff/patients/${patientId}/chargeItems`,
  actions: (patientTypeId) =>
    `/api/artifact/actions?${
      patientTypeId ? "patientTypeId=" + patientTypeId : ""
    }`,
  action: (actionId) => `/api/artifact/actions/${actionId}`,
  newsLetter: (id) =>
    `/api/admin/newsletter/configuration${id ? "/" + id : ""}`,
  getSurveyByName: (surveyName) => `/api/surveys?name=${surveyName}`,
  surveyResponsesCSV: (patientId, surveyId) =>
    `/api/staff/patients/${patientId}/surveys/${surveyId}/csv`,
  deleteInactiveUsers: (facilityId, otp) => {
    if (otp) {
      return `/api/mpsadmin/allusers/${facilityId}/withotp/${otp}`;
    } else {
      return `/api/mpsadmin/allusers/${facilityId}/getotp`;
    }
  },
  patientQuickActions: () => `/api/admin/quick-actions/patient`,
  forceRelogin: () => `/api/forceRelogin`,
  accessTokensAuth: (type) => `/api/video-call/tokens?type=${type}`,
  accessTokens: (type, roomName) =>
    `/api/a/video-call/tokens?type=${type}&roomName=${roomName}`,
  accessTokenFromCode: (type, code) =>
    `/api/staff/access-token?type=${type}&code=${code}`,
  addVideoParticipants: () => `/api/staff/video-calls/add-participants`,
  endVideoCall: () => `/api/staff/end-video-call`,
  revokeWebexAccess: () => `/api/staff/webexauth`,
  patientSurveyImportFromCSV: (survey) =>
    `api/staff/assessments/${survey}/fromcsv`,
  fetchAGPData: (patientId, fromDate, toDate) => {
    let url = `/api/staff/patients/${patientId}/agp`;
    if (fromDate && toDate) {

     fromDate = convertDateFormat(fromDate)
     toDate = convertDateFormat(toDate)

      url += `?`;
      url += `fromDate=${fromDate}`;
      url += `&`;
      url += `toDate=${toDate}`;
    }
    return url;
  },
  getObservationHistory: (patientId, observationCode) =>
    `/api/staff/patients/${patientId}/observations?observationCode=${observationCode}`,
chat: () => `/api/chat`,
addChatConfig: (typeId) => `/api/artifact/patienttypes/${typeId}/chatConfig`,
createContent : `/api/copilot/content`,
  externalSurveyResponse: (patientId)=> `/api/a/survey?p=${patientId}`
    
};

export const PATIENT_API_ENDPOINTS = {
  carePlan: "/api/patient/careplan/",
  patientProfile: "/api/patient/profile",
  updateProfileAttribute: "/api/patient/profile/attributes",
  statusMessages: "/api/patient/artifacts?artifactType=STATUSES_NEW",
  surveyResponses: "/api/patient/artifacts?artifactType=SURVEY_RESPONSES",
  tasks: "/api/patient/tasks",
  provisionedSurveys:
    "/api/patient/artifacts?artifactType=PROVISIONED_SURVEYES",
  infoPanel: "/api/patient/informationPanel",
  consentSubmission: (messageId, consentId) =>
    `/api/patient/messages/${messageId}/consents/${consentId}`,
  surveyResponse: (messageId) =>
    `/api/patient/messages/${messageId}/surveyresponses`,
  appointmentSchedule: (scheduleId) => `/api/signup/schedules/${scheduleId}`,
  createAppointmentFromTemplate: () => `api/signup/appointmentsFromTemplate`,
  groupCalls: (userId, patientId, scheduleId) =>
    `/api/signup/confcallappointments?page=0&size=1000&uid=${userId}&pid=${patientId}&appScheduleId=${scheduleId}`,
  joinGroupCall: (userId, patientId) =>
    `/api/signup/joinConfCall?uid=${userId}&pid=${patientId}`,
  slots: `/api/signup/slots`,
  futureAppointments: `/api/patient/appointments`,
  appointments: `/api/patient/appointments`,
  submittedSurveys: `/api/patient/assessments`,
  surveys: `/api/patient/surveys`,
  surveysResponsesById: (surveyId, page, size) =>
    `/api/patient/assessments/${surveyId}?page=${page}&size=${size}`,
  taskTemplates: `/api/patient/tasktemplates`,
  taskFromTaskTemplate: (taskTemplateId) =>
    `/api/patient/tasktemplates/${taskTemplateId}`,
  carePlanProgress: `/api/patient/careplan/progress`,
  carePlanProgressChecklist: (patientId) => `/api/staff/patients/${patientId}/careplan/progress`,
  medications: `/api/patient/medications`,
  surveyResponsesAdHoc: `/api/patient/surveyresponses`,
  appointmentScreening: (appointmentRefNumber, mrn) =>
    `/api/a/appointments/screening?appointmentRefNumber=${appointmentRefNumber}&mrn=${mrn}`,
  topicsFromMasterData: (id, type) =>
    `/api/masterdata/topics?${id ? `pTypeid=${id}` : ""}&topicType=${type}`,
  actions: () => `/api/actions/execute`,
  anonInfoPanel: (code) => `/api/a/info-panel?ca=${code}`,
  anonLib: (libId, code) => `/api/a/library/${libId}?ca=${code}`,
  regWithings: () => `/api/patient/withings/register`,
};

export const PAGE_SIZE = 20;

export function getServerRegions() {
  return [].concat(Env);
}

export function getAppURL() {
  return getEnvValue("appURL");
}

function setDefaultAPIURL() {
  let url = getAPIURL();
  http.setBaseURL(url);
  StorageService.addToLocalStorage(API_URL_KEY, url);
}

export function getAPIURL() {
  let region = null;
  if (window.location.search) {
    let params = parseURLQueryParams(window.location.search);
    if (params && params.region) {
      region = params.region;
    }
  }

  let url = null;
  if (region) {
    url = getEnvValue("url", region);
  } else if (StorageService.getFromLocalStorage(API_URL_KEY)) {
    url = StorageService.getFromLocalStorage(API_URL_KEY);
  }

  if (!url) {
    url = getEnvValue("url");
  }
  return url;
}

setDefaultAPIURL();

function getEnvValue(field, region) {
  if (!region) {
    region = getRegionFromDomain();
  }
  let filtered = Env.filter((host) => host.regionCode === region);
  let value = filtered && filtered[0] ? filtered[0][field] : null;
  value = value || Env[0][field];
  return value;
}

function convertDateFormat(dateString) {
  // Split the input date string into components
  let [year, month, day] = dateString.split('-');

  // Return the date in the desired format
  let formatted = `${day}${month}${year}`
  return formatted;
}

function getRegionFromDomain() {
  let regionMapping = {
    "web.mypatientspace.com": "eu",
    "latam.mypatientspace.com": "eu",
    "uae.mypatientspace.com": "gcc",
    "mea.mypatientspace.com": "gcc",
  };

  return regionMapping[document.domain] || "eu";
}

export function isTest() {
  return (
    document.domain === "preprod.mypatientspace.com" ||
    document.domain === "testweb.mypatientspace.com" ||
    document.domain === "localhost"
  );
}

export const WEBEX_OAUTH_URL = "https://webexapis.com/v1/authorize";
